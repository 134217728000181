import { NextButton } from '../Button'
import Slide, { ActiveHandler, SC } from '.'
import { useCallback } from 'react'
import { Languages, useGlobalState } from '../../state'

const greetingText: Record<Languages, JSX.Element> = {
  de: (
    <>
      <p>
        Hi!
      </p>
      <p>
        Wir freuen uns darüber, dass du dich dazu entschieden hast, den TALENTBRÜCKE - Berufsinteressentest zu bearbeiten.
      </p>
      <p>
        Wir nennen dir gleich verschiedene Tätigkeiten, zu denen du angeben sollst, wie sehr diese dich interessieren.
        Hierbei ist es nicht wichtig, ob du denkst, dass du diese Tätigkeiten ausführen kannst, sondern nur, wie viel
        Spaß es dir machen würde. Du kannst Werte von 1 (interessiert mich gar nicht) bis 5 (interessiert mich sehr)
        vergeben.
      </p>
      <p>
        Bitte lies dir zunächst die Datenschutzerklärung durch und bestätige diese, um den Berufsinteressentest
        durchführen zu können.
      </p>
    </>
  ),
  en: (
    <>
      <p>
        Hi!
      </p>
      <p>
        We are pleased that you have decided to do the TALENTBRÜCKE career interest test.
      </p>
      <p>
        We will give you a list of different job activities and ask you to indicate how interested you are in each of
        them. It is not important whether you think you can do these job activities, but only how much you would enjoy
        doing them. You can give values from 1 (I am not interested at all) to 5 (I am very interested).
      </p>
      <p>
        Please read the data protection declaration first and confirm it in order to be able to complete the career
        interest test.
      </p>
    </>
  ),
  es: (
    <>
      <p>
        ¡Hola!
      </p>
      <p>
        Nos complace que te hayas decidido a realizar la prueba de interés profesional de TALENTBRÜCKE.
      </p>
      <p>
        A continuación te presentaremos diferentes trabajos para los cuales deberás indicar tu interés. No importa si
        crees poder hacer estas actividades, sino únicamente cuánto disfrutarías haciéndolas. Valora las siguientes
        actividades del 1 al 5, siendo 1 “no me interesa en absoluto” y 5 “me interesa mucho”.
      </p>
      <p>
        Por favor, lee primero la declaración de protección de datos y confírmala para poder iniciar la prueba de
        interés profesional.
      </p>
    </>
  )
}

const GreetingSlide: SC = ({ status }) => {
  const { decrementSlide, incrementSlide, language } = useGlobalState()

  const keyEventHandler = useCallback<ActiveHandler>(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        incrementSlide()
      } else if (event.key === 'Escape') {
        event.preventDefault()
        decrementSlide()
      }
    }

    window.addEventListener('keydown', callback)

    return () => window.removeEventListener('keydown', callback)
  }, [incrementSlide])

  return (
    <Slide name="greeting" status={status} onActive={keyEventHandler}>
      {greetingText[language]}
      <NextButton id="next_greeting" onClick={incrementSlide}/>
    </Slide>
  )
}

export default GreetingSlide
