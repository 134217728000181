import Slide, { SC } from '.'
import React, { useState } from 'react'
import { Facebook, Instagram, Twitter } from 'react-bootstrap-icons'
import { Languages, OccupationGroup, useGlobalState } from '../../state'
import EmailModal from '../EmailModal'

export const getPoints = (
  questions: ReturnType<typeof useGlobalState>['questions'],
  answers: ReturnType<typeof useGlobalState>['answers']
): Record<'r' | 'i' | 'a' | 's' | 'e' | 'c', number> => {
  const points = {
    r: 0,
    i: 0,
    a: 0,
    s: 0,
    e: 0,
    c: 0
  }

  for (let i = 0; i < questions.length; i++) {
    const { id, group } = questions[i],
      answer = answers[id],
      char = OccupationGroup[group].toLowerCase() as 'r' | 'i' | 'a' | 's' | 'e' | 'c'

    points[char] += answer
  }

  return points
}

const evaluation_text: Record<Languages, JSX.Element> = {
  de: <div><h2> Vielen Dank für deine Antworten und deine Zeit. </h2>
    Um deine Ergebnisse zu speichern, kannst du auf „Herunterladen“ klicken und dir deinen Ergebnisbericht sofort
    herunterladen.<br/><br/>
    Sollte dies gerade nicht möglich sein, so klicke auf „Per E-Mail senden“. Dort kannst du die
    E-Mailadresse angeben, an welchen wir deinen Ergebnisbericht versenden sollen.
    Bitte beachte, dass wir keinerlei Daten speichern. Sobald du diese Seite schließt, wird dein
    Ergebnisbericht
    gelöscht und kann nicht reproduziert werden. Du kannst den TALENTBRÜCKE – Berufsinteressentest natürlich
    jederzeit wiederholen.<br/><br/>
    Solltest du weitere Fragen haben, so kannst du uns jederzeit per E-Mail oder Telefon
    kontaktieren.<br/><br/>
    <span style={{ fontSize: '1.2em' }}> Dein Team der TALENTBRÜCKE </span>
  </div>,
  en: <div><h2>Thank you for your answers and your time. </h2>
    To save your results, you can click on "Download" and immediately download your result
    report. <br/><br/>
    If this is not possible at the moment, click on "Send by e-mail". There you can enter the e-mail address
    to
    which we should send your result report.
    Please note that we do not store any data. As soon as you close this page, your result report will be
    deleted and cannot be reproduced. Of course, you can repeat the TALENTBRÜCKE - Berufsinteressentest at
    any
    time.<br/><br/>
    If you have any further questions, please do not hesitate to contact us by e-mail or phone.<br/><br/>
    <span style={{ fontSize: '1.2em' }}> Your TALENTBRÜCKE team </span>
  </div>,
  es: <div><h2> Gracias por sus respuestas y su tiempo. </h2>
    Para guardar sus resultados, puede hacer clic en "Descargar" y descargar inmediatamente su informe de
    resultados.<br/><br/>
    Si esto no es posible en este momento, haga clic en "Enviar por correo electrónico". Allí puede
    introducir
    la dirección de correo electrónico a la que debemos enviar su informe de resultados.
    Tenga en cuenta que no almacenamos ningún dato. En cuanto cierre esta página, su informe de resultados
    será
    eliminado y no podrá ser reproducido. Por supuesto, puede repetir el TALENTBRÜCKE - Berufsinteressentest
    en
    cualquier momento.<br/><br/>
    Si tiene más preguntas, no dude en ponerse en contacto con nosotros por correo electrónico o por
    teléfono.<br/><br/>
    <span style={{ fontSize: '1.2em' }}> Su equipo de TALENTBRÜCKE </span>
  </div>
}

const output_button_text: Record<Languages, string> = {
  de: 'Herunterladen',
  en: 'Download',
  es: 'Descargue'
}

const mail_button_text: Record<Languages, string> = {
  de: 'Per E-Mail senden',
  en: 'Send by e-mail',
  es: 'Enviar por e-mail'
}

const EvaluationSlide: SC = ({ status }) => {

  const { answers, questions, language, firstName, lastName } = useGlobalState()
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Slide name="evaluation" status={status}>
        <div className="evaluation-container" id={'evaluation-container'}>
          <h1>
            <img src="/assets/logo_tb.png" alt="TALENTBRÜCKE"/>
          </h1>
          {evaluation_text[language]}
          <div className={'options'}>
            <div className={'other-buttons'}>
              <button onClick={() => setShowModal(!showModal)}>
                {mail_button_text[language]}
              </button>
              <form style={{ margin: '0', padding: '0' }} action={language === 'es' ? '/prueba_de_interes_profesional.pdf' : (language === 'en' ? '/career_interest_test.pdf' : '/berufsinteressentest.pdf')} method="post" target="_blank">
                {Object.entries(getPoints(questions, answers)).map(([key, value]) => <input type="hidden" name={key}
                                                                                            value={value.toString()}
                                                                                            key={key}/>)}
                <input type="hidden" name="first_name" value={firstName}/>
                <input type="hidden" name="last_name" value={lastName}/>
                <input type="hidden" name="lang" value={language}/>
                <button type="submit">{output_button_text[language ?? 'de']}</button>
              </form>
            </div>
            <div className={'social-media-buttons'}>
              {
                [{
                  icon: <Instagram/>,
                  class: 'instagram',
                  link: 'https://www.instagram.com/talentbruecke'
                },
                  {
                    icon: <Facebook/>,
                    class: 'facebook',
                    link: 'https://www.facebook.com/TalentbrueckeGmbH'
                  },
                  {
                    icon: <Twitter/>,
                    class: 'twitter',
                    link: 'https://twitter.com/talentbruecke'
                  }].map((icon, idx) =>
                  <a href={icon.link} target="_blank" className={'social-media-button-wrapper'} key={idx}
                     rel="noreferrer">
                    <button className={`social-media-button type-${icon.class}`}>
                      {icon.icon}
                    </button>
                  </a>
                )}
            </div>
          </div>
        </div>
      </Slide>
      <EmailModal show={showModal} large={true} handleClose={() => setShowModal(!showModal)}/>
    </>
  )
}

export default EvaluationSlide
