import React, { cloneElement, useMemo } from 'react'
import './App.css'
import ProgressBar from './components/ProgressBar'
import {
    Evaluation,
    FirstName,
    GoodBye,
    Greeting,
    Halftime,
    LanguageSelect,
    LastName,
    PrivacyPolicy,
    Question
} from './components/slides'
import { PolicyState, useGlobalState } from './state'

function App() {
    const { currentSlide, questions, policyState } = useGlobalState()

    const slides = useMemo(() => {
        const arr = [
            <LanguageSelect key='language-select' status='active' />,
            <Greeting key='greeting' status='next' />,
            <PrivacyPolicy key='privacy-policy' status='next' />
        ]

        switch (policyState) {
            case PolicyState.DECLINED:
                arr.push(<GoodBye key='goodbye' status='next' />)
                break
            case PolicyState.ACCEPTED:
                arr.push(<FirstName key='first-name' status='next'/>)
                for (let i = 0; i < questions.length; i++) {
                    arr.push(<Question key={`question_${i}`} status='next' question={questions[i]} />)
                    if (i === Math.floor(questions.length / 2))
                        arr.push(<Halftime key='half-time' status='next' />)
                }
                arr.push(
                  <LastName key='last-name' status='next' />,
                  <Evaluation key='evaluation' status='next' />
                )
        }


        return arr
    }, [policyState, questions])


    return (
        <>
            <ProgressBar max={slides.length - 4} value={currentSlide - 3}/>
            <main>
                {slides.map((slide, idx) => cloneElement(slide, {status: currentSlide === idx ? 'active' : currentSlide < idx ? 'next' : 'prev'}))}
            </main>
        </>
    )
}

export default App
