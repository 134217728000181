import Slide from './Slide'

import Evaluation from './Evaluation'
import FirstName from './FirstName'
import GoodBye from './GoodBye'
import Greeting from './Greeting'
import Halftime from './HalftimeSlide'
import LanguageSelect from './LanguageSelect'
import LastName from './LastName'
import PrivacyPolicy from './PrivacyPolicy'
import Question from './Question'

export default Slide
export * from './Slide'

export {
  Evaluation,
  FirstName,
  GoodBye,
  Greeting,
  Halftime,
  LanguageSelect,
  LastName,
  PrivacyPolicy,
  Question
}
