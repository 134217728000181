import Slide, { ActiveHandler, SC } from '.'
import { Languages, useGlobalState } from '../../state'
import { useCallback } from 'react'
import { backText } from '../Button'

const goodbyeString: Record<Languages, { big: string, small: string }> = {
  de: {
    big: 'Vielen Dank',
    small: 'und auf Wiedersehen'
  },
  en: {
    big: 'Thank you',
    small: 'and goodbye'
  },
  es: {
    big: 'Gracias',
    small: 'y adiós'
  }
}

const GoodBye: SC = ({ status }) => {
  const { language, decrementSlide } = useGlobalState()

  const keyEventHandler = useCallback<ActiveHandler>(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        decrementSlide()
      }
    }

    window.addEventListener('keydown', callback)

    return () => window.removeEventListener('keydown', callback)
  }, [decrementSlide])

  return (
    <Slide name='goodbye' status={status} onActive={keyEventHandler}>
      <h2>
        {goodbyeString[language].big}
        <small>{goodbyeString[language].small}</small>
      </h2>
      <div className="actions">
        <button onClick={decrementSlide}>{backText[language]}</button>
      </div>
    </Slide>
  )
}

export default GoodBye
